.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.section-1 {
    background-color: #101010;
}

.section-2 {
    background-color: #f1f1f1;
}

.console {
    color: #f1f1f1;
    display: inline-block;
    position: absolute;
    width: 100%;
    bottom: 0;
}

h1 {
    font-weight: 700;
    font-size: 70px;
    transition: margin .5s;
}

.text-s1 {
    color: #f1f1f1;
}

.text-s2 {
    color: #101010;
}

.title-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
}

.title {
    text-align: center;
}

.scroll-arrow {
    cursor: pointer;
}


.Cursor {
    color: #101010;
}


@media (max-width: 800px) {
    .console {
        color: #f1f1f1;
        display: inline-block;
        width: 100%;
        bottom: 0;
        font-size: 10px;
    }

    h1 {
        font-weight: 700;
        font-size: 50px;
        transition: margin .5s;
    }
        
}